<template>
  <div v-if="getCorrectVideo(data)" class="hp-video" ref="hpVideoContainer">
    <ClientOnly>
      <vueVimeoPlayer
        v-bind="getVimeoPlayerOptions(data)"
        class="hp-video__player"
        :class="{ square: squareVideo }"
        @ended="playing = false"
        ref="hpVideoPlayer"
      />
    </ClientOnly>
    <span v-if="hasPlayIcon" class="hp-video__play" @click="handlePlay">
      {{ playing ? 'PAUSE' : 'PLAY' }}
    </span>
    <span v-if="hasMuteIcon" class="hp-video__mute" @click="handleMute">
      {{ muted ? 'SOUND ON' : 'SOUND OFF' }}</span
    >
  </div>
</template>

<script>
import { ref, defineComponent } from '@nuxtjs/composition-api';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { useWindow } from '~/composables';
import { SoundOn, SoundOff } from '~/components/General/Icons';

export default defineComponent({
  name: 'HomepageVideo',
  components: {
    vueVimeoPlayer,
    SoundOn,
    SoundOff,
  },
  props: {
    videoRef: {
      type: String,
      default: `hp-video-player`,
    },
    data: {
      type: Object,
      default: () => null,
    },
    squareVideo: {
      type: Boolean,
      default: false,
    },
    hasMuteIcon: {
      type: Boolean,
      default: false,
    },
    hasPlayIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();

    const hpVideoPlayer = ref();
    const hpVideoContainer = ref();
    const muted = ref(true);
    const playing = ref(true);

    const getCorrectVideo = (data) =>
      isDesktop.value ? data.videoDesktop : data.videoMobile;

    const getVimeoPlayerOptions = (data) => {
      const vimeoPlayerOptions = {
        ref: props.videoRef,
        autoplay: true,
        controls: false,
        loop: true,
        options: {
          muted: true,
          autopause: false,
        },
      };
      const correctVideo = getCorrectVideo(data);
      vimeoPlayerOptions[
        correctVideo.includes('http') ? 'video-url' : 'video-id'
      ] = correctVideo;
      return vimeoPlayerOptions;
    };

    const handleMute = () => {
      if (hpVideoPlayer.value) {
        muted.value ? hpVideoPlayer.value.unmute() : hpVideoPlayer.value.mute();
        muted.value = !muted.value;
      }
    };

    /**
     *  The function first checks if the hpVideoPlayer variable is defined and not null.
     * The hpVideoPlayer variable is a reference to the video player element on the homepage.
     * If the hpVideoPlayer variable is defined, the function toggles the play/pause state of the video player.
     * This is done by checking the value of the playing variable.
     * If the playing variable is true, the function pauses the video player using the pause method of the hpVideoPlayer object.
     * If the playing variable is false, the function plays the video player using the play method of the hpVideoPlayer object.
     * After toggling the play/pause state of the video player, the playing variable is updated to reflect the new state.
     */
    const handlePlay = () => {
      if (hpVideoPlayer.value) {
        playing.value
          ? hpVideoPlayer.value.pause()
          : hpVideoPlayer.value.play();
        playing.value = !playing.value;
      }
    };

    return {
      handleMute,
      getCorrectVideo,
      getVimeoPlayerOptions,
      hpVideoPlayer,
      muted,
      handlePlay,
      playing,
      hpVideoContainer,
    };
  },
});
</script>

<style lang="scss" scoped>
.hp-video {
  position: relative;
  &__player {
    padding: 200% 0 0 0;
    @include to-tablet-max {
      padding: 176% 0 0 0;
    }
    position: relative;
    width: 100%;
    @include desktop-boxed;
    &.square {
      padding: 98% 0 0 0;
    }
  }
  &__mute,
  &__play {
    position: absolute;
    bottom: 0.625rem;
    height: fit-content;
    width: fit-content;
    background: var(--c-white);
    padding: 0.3125rem;
    &:hover {
      cursor: pointer;
    }
  }
  &__mute {
    right: 0.625rem;
  }
  &__play {
    left: 0.625rem;
  }
  @include from-desktop-min {
    &__player {
      padding: 56.25% 0 0 0; // 56.25% -> 16:9
      &.square {
        padding: 97% 0 0 0;
      }
    }
  }
}
</style>

<style lang="scss">
.hp-video__player {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
